document.addEventListener('DOMContentLoaded', function() {
  const iconPaths = [
    "../img/icons/amp-guitar.svg",
    "../img/icons/apple-whole.svg",
    "../img/icons/axe.svg",
    "../img/icons/baby-carriage.svg",
    "../img/icons/backpack.svg",
    "../img/icons/bacon.svg",
    "../img/icons/bag-seedling.svg",
    "../img/icons/bag-shopping.svg",
    "../img/icons/baguette.svg",
    "../img/icons/balloon.svg",
    "../img/icons/baseball-bat-ball.svg",
    "../img/icons/baseball.svg",
    "../img/icons/basket-shopping.svg",
    "../img/icons/basketball.svg",
    "../img/icons/bath.svg",
    "../img/icons/beer-mug.svg",
    "../img/icons/bicycle.svg",
    "../img/icons/binoculars.svg",
    "../img/icons/blender.svg",
    "../img/icons/bone.svg",
    "../img/icons/book-blank.svg",
    "../img/icons/book-open.svg",
    "../img/icons/boot-heeled.svg",
    "../img/icons/bowling-ball-pin.svg",
    "../img/icons/bread-loaf.svg",
    "../img/icons/briefcase.svg",
    "../img/icons/bullhorn.svg",
    "../img/icons/burger-lettuce.svg",
    "../img/icons/burger-soda.svg",
    "../img/icons/cabinet-filing.svg",
    "../img/icons/cake-slice.svg",
    "../img/icons/calculator.svg",
    "../img/icons/camcorder.svg",
    "../img/icons/camera-polaroid.svg",
    "../img/icons/camera-retro.svg",
    "../img/icons/candle-holder.svg",
    "../img/icons/candy-bar.svg",
    "../img/icons/candy-cane.svg",
    "../img/icons/candy.svg",
    "../img/icons/capsules.svg",
    "../img/icons/carrot.svg",
    "../img/icons/cheese-swiss.svg",
    "../img/icons/coconut.svg",
    "../img/icons/coin-blank.svg",
    "../img/icons/computer-mouse-scrollwheel.svg",
    "../img/icons/cookie.svg",
    "../img/icons/croissant.svg",
    "../img/icons/cup-straw.svg",
    "../img/icons/cup-togo.svg",
    "../img/icons/donut.svg",
    "../img/icons/drone-front.svg",
    "../img/icons/drum.svg",
    "../img/icons/dryer.svg",
    "../img/icons/dumbbell.svg",
    "../img/icons/fan.svg",
    "../img/icons/fish.svg",
    "../img/icons/flashlight.svg",
    "../img/icons/football.svg",
    "../img/icons/french-fries.svg",
    "../img/icons/futbol.svg",
    "../img/icons/game-board.svg",
    "../img/icons/game-console-handheld.svg",
    "../img/icons/gamepad-modern.svg",
    "../img/icons/gamepad.svg",
    "../img/icons/gift.svg",
    "../img/icons/globe-stand.svg",
    "../img/icons/grill.svg",
    "../img/icons/guitar-electric.svg",
    "../img/icons/guitar.svg",
    "../img/icons/hammer.svg",
    "../img/icons/hard-drive.svg",
    "../img/icons/headphones-simple.svg",
    "../img/icons/honey-pot.svg",
    "../img/icons/jar.svg",
    "../img/icons/keyboard.svg",
    "../img/icons/knife-kitchen.svg",
    "../img/icons/lamp-desk.svg",
    "../img/icons/lamp-floor.svg",
    "../img/icons/lamp.svg",
    "../img/icons/lemon.svg",
    "../img/icons/lollipop.svg",
    "../img/icons/microchip.svg",
    "../img/icons/microphone.svg",
    "../img/icons/microwave.svg",
    "../img/icons/mobile.svg",
    "../img/icons/mug-tea.svg",
    "../img/icons/mug.svg",
    "../img/icons/pizza-slice.svg",
    "../img/icons/print.svg",
    "../img/icons/projector.svg",
    "../img/icons/racquet.svg",
    "../img/icons/radio.svg",
    "../img/icons/ring-diamond.svg",
    "../img/icons/saxophone.svg",
    "../img/icons/shirt-long-sleeve.svg",
    "../img/icons/shirt.svg",
    "../img/icons/shovel.svg",
    "../img/icons/shuttlecock.svg",
    "../img/icons/soft-serve.svg",
    "../img/icons/speaker.svg",
    "../img/icons/strawberry.svg",
    "../img/icons/suitcase-rolling.svg",
    "../img/icons/ticket.svg",
    "../img/icons/vacuum.svg",
    "../img/icons/washing-machine.svg",
    "../img/icons/watch.svg",
    "../img/icons/wine-bottle.svg",
    "../img/icons/wine-glass-empty.svg",
  ];

  const numberOfIcons = 40;
  const fixedSize = 50;
  const sizeVariability = 0.5;

  const body = document.body;

  // Check if the viewport width is greater than 768 pixels (adjust as needed)
  if (window.innerWidth > 768) {
    for (let i = 0; i < numberOfIcons; i++) {
      const newIcon = document.createElement("img");
      const angle = Math.random() * 360;
      const iconPath = iconPaths[Math.floor(Math.random() * iconPaths.length)];

      newIcon.src = iconPath;

      const x = Math.random() * body.scrollWidth;
      const y = Math.random() * body.scrollHeight;

      const randomizedSize = fixedSize * (1 + (Math.random() - 0.5) * sizeVariability);

      newIcon.style.cssText = `
          position: absolute;
          left: ${x}px;
          top: ${y}px;
          width: ${randomizedSize}px;
          height: ${randomizedSize}px;
          transform: rotate(${angle}deg);
          z-index: -1;
      `;

      body.appendChild(newIcon);
    }
  }
});